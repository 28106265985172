import { mapGetters } from "vuex"
export default {
  name: "NotFound",
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn"
    }),
    helpCenterUrl() {
      return process.env.VUE_APP_HELP_CENTER_URL
    }
  },
  watch: {
  }
}
